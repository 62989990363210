/*
 * This combined file was created by the DataTables downloader builder:
 *   https://datatables.net/download
 *
 * To rebuild or modify this file with the latest versions of the included
 * software please visit:
 *   https://datatables.net/download/#dt/b-1.1.2,b-colvis-1.1.2,b-flash-1.1.2,b-html5-1.1.2,b-print-1.1.2
 *
 * Included libraries:
 *   Buttons 1.1.2, Column visibility 1.1.2, Flash export 1.1.2, HTML5 export 1.1.2, Print view 1.1.2
 */

/*!
 Buttons for DataTables 1.1.2
 ©2015 SpryMedia Ltd - datatables.net/license
*/
(function(e){"function"===typeof define&&define.amd?define(["jquery","datatables.net"],function(p){return e(p,window,document)}):"object"===typeof exports?module.exports=function(p,o){p||(p=window);if(!o||!o.fn.dataTable)o=require("datatables.net")(p,o).$;return e(o,p,p.document)}:e(jQuery,window,document)})(function(e,p,o,n){var j=e.fn.dataTable,t=0,u=0,l=j.ext.buttons,m=function(a,b){!0===b&&(b={});e.isArray(b)&&(b={buttons:b});this.c=e.extend(!0,{},m.defaults,b);b.buttons&&(this.c.buttons=b.buttons);
this.s={dt:new j.Api(a),buttons:[],subButtons:[],listenKeys:"",namespace:"dtb"+t++};this.dom={container:e("<"+this.c.dom.container.tag+"/>").addClass(this.c.dom.container.className)};this._constructor()};e.extend(m.prototype,{action:function(a,b){var c=this._indexToButton(a).conf;if(b===n)return c.action;c.action=b;return this},active:function(a,b){var c=this._indexToButton(a),d=this.c.dom.button.active;if(b===n)return c.node.hasClass(d);c.node.toggleClass(d,b===n?!0:b);return this},add:function(a,
b){if("string"===typeof a&&-1!==a.indexOf("-")){var c=a.split("-");this.c.buttons[1*c[0]].buttons.splice(1*c[1],0,b)}else this.c.buttons.splice(1*a,0,b);this.dom.container.empty();this._buildButtons(this.c.buttons);return this},container:function(){return this.dom.container},disable:function(a){this._indexToButton(a).node.addClass(this.c.dom.button.disabled);return this},destroy:function(){e("body").off("keyup."+this.s.namespace);var a=this.s.buttons,b=this.s.subButtons,c,d,f;c=0;for(a=a.length;c<
a;c++){this.removePrep(c);d=0;for(f=b[c].length;d<f;d++)this.removePrep(c+"-"+d)}this.removeCommit();this.dom.container.remove();b=this.s.dt.settings()[0];c=0;for(a=b.length;c<a;c++)if(b.inst===this){b.splice(c,1);break}return this},enable:function(a,b){if(!1===b)return this.disable(a);this._indexToButton(a).node.removeClass(this.c.dom.button.disabled);return this},name:function(){return this.c.name},node:function(a){return this._indexToButton(a).node},removeCommit:function(){var a=this.s.buttons,
b=this.s.subButtons,c,d;for(c=a.length-1;0<=c;c--)null===a[c]&&(a.splice(c,1),b.splice(c,1),this.c.buttons.splice(c,1));c=0;for(a=b.length;c<a;c++)for(d=b[c].length-1;0<=d;d--)null===b[c][d]&&(b[c].splice(d,1),this.c.buttons[c].buttons.splice(d,1));return this},removePrep:function(a){var b,c=this.s.dt;if("number"===typeof a||-1===a.indexOf("-"))b=this.s.buttons[1*a],b.conf.destroy&&b.conf.destroy.call(c.button(a),c,b,b.conf),b.node.remove(),this._removeKey(b.conf),this.s.buttons[1*a]=null;else{var d=
a.split("-");b=this.s.subButtons[1*d[0]][1*d[1]];b.conf.destroy&&b.conf.destroy.call(c.button(a),c,b,b.conf);b.node.remove();this._removeKey(b.conf);this.s.subButtons[1*d[0]][1*d[1]]=null}return this},text:function(a,b){var c=this._indexToButton(a),d=this.c.dom.collection.buttonLiner,d="string"===typeof a&&-1!==a.indexOf("-")&&d&&d.tag?d.tag:this.c.dom.buttonLiner.tag,e=this.s.dt,h=function(a){return"function"===typeof a?a(e,c.node,c.conf):a};if(b===n)return h(c.conf.text);c.conf.text=b;d?c.node.children(d).html(h(b)):
c.node.html(h(b));return this},toIndex:function(a){var b,c,d,e;d=this.s.buttons;var h=this.s.subButtons;b=0;for(c=d.length;b<c;b++)if(d[b].node[0]===a)return b+"";b=0;for(c=h.length;b<c;b++){d=0;for(e=h[b].length;d<e;d++)if(h[b][d].node[0]===a)return b+"-"+d}},_constructor:function(){var a=this,b=this.s.dt,c=b.settings()[0];c._buttons||(c._buttons=[]);c._buttons.push({inst:this,name:this.c.name});this._buildButtons(this.c.buttons);b.on("destroy",function(){a.destroy()});e("body").on("keyup."+this.s.namespace,
function(b){if(!o.activeElement||o.activeElement===o.body){var c=String.fromCharCode(b.keyCode).toLowerCase();a.s.listenKeys.toLowerCase().indexOf(c)!==-1&&a._keypress(c,b)}})},_addKey:function(a){a.key&&(this.s.listenKeys+=e.isPlainObject(a.key)?a.key.key:a.key)},_buildButtons:function(a,b,c){var d=this.s.dt,f=0;b||(b=this.dom.container,this.s.buttons=[],this.s.subButtons=[]);for(var h=0,i=a.length;h<i;h++){var k=this._resolveExtends(a[h]);if(k)if(e.isArray(k))this._buildButtons(k,b,c);else{var g=
this._buildButton(k,c!==n?!0:!1);if(g){var r=g.node;b.append(g.inserter);c===n?(this.s.buttons.push({node:r,conf:k,inserter:g.inserter}),this.s.subButtons.push([])):this.s.subButtons[c].push({node:r,conf:k,inserter:g.inserter});k.buttons&&(g=this.c.dom.collection,k._collection=e("<"+g.tag+"/>").addClass(g.className),this._buildButtons(k.buttons,k._collection,f));k.init&&k.init.call(d.button(r),d,r,k);f++}}}},_buildButton:function(a,b){var c=this.c.dom.button,d=this.c.dom.buttonLiner,f=this.c.dom.collection,
h=this.s.dt,i=function(b){return"function"===typeof b?b(h,g,a):b};b&&f.button&&(c=f.button);b&&f.buttonLiner&&(d=f.buttonLiner);if(a.available&&!a.available(h,a))return!1;var k=function(a,b,c,d){d.action.call(b.button(c),a,b,c,d);e(b.table().node()).triggerHandler("buttons-action.dt",[b.button(c),b,c,d])},g=e("<"+c.tag+"/>").addClass(c.className).attr("tabindex",this.s.dt.settings()[0].iTabIndex).attr("aria-controls",this.s.dt.table().node().id).on("click.dtb",function(b){b.preventDefault();!g.hasClass(c.disabled)&&
a.action&&k(b,h,g,a);g.blur()}).on("keyup.dtb",function(b){b.keyCode===13&&!g.hasClass(c.disabled)&&a.action&&k(b,h,g,a)});d.tag?g.append(e("<"+d.tag+"/>").html(i(a.text)).addClass(d.className)):g.html(i(a.text));!1===a.enabled&&g.addClass(c.disabled);a.className&&g.addClass(a.className);a.titleAttr&&g.attr("title",a.titleAttr);a.namespace||(a.namespace=".dt-button-"+u++);d=(d=this.c.dom.buttonContainer)&&d.tag?e("<"+d.tag+"/>").addClass(d.className).append(g):g;this._addKey(a);return{node:g,inserter:d}},
_indexToButton:function(a){if("number"===typeof a||-1===a.indexOf("-"))return this.s.buttons[1*a];a=a.split("-");return this.s.subButtons[1*a[0]][1*a[1]]},_keypress:function(a,b){var c,d,f,h;f=this.s.buttons;var i=this.s.subButtons,k=function(c,d){if(c.key)if(c.key===a)d.click();else if(e.isPlainObject(c.key)&&c.key.key===a&&(!c.key.shiftKey||b.shiftKey))if(!c.key.altKey||b.altKey)if(!c.key.ctrlKey||b.ctrlKey)(!c.key.metaKey||b.metaKey)&&d.click()};c=0;for(d=f.length;c<d;c++)k(f[c].conf,f[c].node);
c=0;for(d=i.length;c<d;c++){f=0;for(h=i[c].length;f<h;f++)k(i[c][f].conf,i[c][f].node)}},_removeKey:function(a){if(a.key){var b=e.isPlainObject(a.key)?a.key.key:a.key,a=this.s.listenKeys.split(""),b=e.inArray(b,a);a.splice(b,1);this.s.listenKeys=a.join("")}},_resolveExtends:function(a){for(var b=this.s.dt,c,d,f=function(c){for(var d=0;!e.isPlainObject(c)&&!e.isArray(c);){if(c===n)return;if("function"===typeof c){if(c=c(b,a),!c)return!1}else if("string"===typeof c){if(!l[c])throw"Unknown button type: "+
c;c=l[c]}d++;if(30<d)throw"Buttons: Too many iterations";}return e.isArray(c)?c:e.extend({},c)},a=f(a);a&&a.extend;){if(!l[a.extend])throw"Cannot extend unknown button type: "+a.extend;var h=f(l[a.extend]);if(e.isArray(h))return h;if(!h)return!1;c=h.className;a=e.extend({},h,a);c&&a.className!==c&&(a.className=c+" "+a.className);var i=a.postfixButtons;if(i){a.buttons||(a.buttons=[]);c=0;for(d=i.length;c<d;c++)a.buttons.push(i[c]);a.postfixButtons=null}if(i=a.prefixButtons){a.buttons||(a.buttons=[]);
c=0;for(d=i.length;c<d;c++)a.buttons.splice(c,0,i[c]);a.prefixButtons=null}a.extend=h.extend}return a}});m.background=function(a,b,c){c===n&&(c=400);a?e("<div/>").addClass(b).css("display","none").appendTo("body").fadeIn(c):e("body > div."+b).fadeOut(c,function(){e(this).remove()})};m.instanceSelector=function(a,b){if(!a)return e.map(b,function(a){return a.inst});var c=[],d=e.map(b,function(a){return a.name}),f=function(a){if(e.isArray(a))for(var i=0,k=a.length;i<k;i++)f(a[i]);else"string"===typeof a?
-1!==a.indexOf(",")?f(a.split(",")):(a=e.inArray(e.trim(a),d),-1!==a&&c.push(b[a].inst)):"number"===typeof a&&c.push(b[a].inst)};f(a);return c};m.buttonSelector=function(a,b){for(var c=[],d=function(a,b){var g,f,h=[];e.each(b.s.buttons,function(a,b){null!==b&&h.push({node:b.node[0],name:b.conf.name})});e.each(b.s.subButtons,function(a,b){e.each(b,function(a,b){null!==b&&h.push({node:b.node[0],name:b.conf.name})})});g=e.map(h,function(a){return a.node});if(e.isArray(a)||a instanceof e){g=0;for(f=a.length;g<
f;g++)d(a[g],b)}else if(null===a||a===n||"*"===a){g=0;for(f=h.length;g<f;g++)c.push({inst:b,idx:b.toIndex(h[g].node)})}else if("number"===typeof a)c.push({inst:b,idx:a});else if("string"===typeof a)if(-1!==a.indexOf(",")){var j=a.split(",");g=0;for(f=j.length;g<f;g++)d(e.trim(j[g]),b)}else if(a.match(/^\d+(\-\d+)?$/))c.push({inst:b,idx:a});else if(-1!==a.indexOf(":name")){j=a.replace(":name","");g=0;for(f=h.length;g<f;g++)h[g].name===j&&c.push({inst:b,idx:b.toIndex(h[g].node)})}else e(g).filter(a).each(function(){c.push({inst:b,
idx:b.toIndex(this)})});else"object"===typeof a&&a.nodeName&&(f=e.inArray(a,g),-1!==f&&c.push({inst:b,idx:b.toIndex(g[f])}))},f=0,h=a.length;f<h;f++)d(b,a[f]);return c};m.defaults={buttons:["copy","excel","csv","pdf","print"],name:"main",tabIndex:0,dom:{container:{tag:"div",className:"dt-buttons"},collection:{tag:"div",className:"dt-button-collection"},button:{tag:"a",className:"dt-button",active:"active",disabled:"disabled"},buttonLiner:{tag:"span",className:""}}};m.version="1.1.2";e.extend(l,{collection:{text:function(a){return a.i18n("buttons.collection",
"Collection")},className:"buttons-collection",action:function(a,b,c,d){var a=c.offset(),f=e(b.table().container()),h=!1;e("div.dt-button-background").length&&(h=e("div.dt-button-collection").offset(),e(o).trigger("click.dtb-collection"));d._collection.addClass(d.collectionLayout).css("display","none").appendTo("body").fadeIn(d.fade);var i=d._collection.css("position");h&&"absolute"===i?d._collection.css({top:h.top+5,left:h.left+5}):"absolute"===i?(d._collection.css({top:a.top+c.outerHeight(),left:a.left}),
c=a.left+d._collection.outerWidth(),f=f.offset().left+f.width(),c>f&&d._collection.css("left",a.left-(c-f))):(a=d._collection.height()/2,a>e(p).height()/2&&(a=e(p).height()/2),d._collection.css("marginTop",-1*a));d.background&&m.background(!0,d.backgroundClassName,d.fade);setTimeout(function(){e("div.dt-button-background").on("click.dtb-collection",function(){});e("body").on("click.dtb-collection",function(a){if(!e(a.target).parents().andSelf().filter(d._collection).length){d._collection.fadeOut(d.fade,
function(){d._collection.detach()});e("div.dt-button-background").off("click.dtb-collection");m.background(false,d.backgroundClassName,d.fade);e("body").off("click.dtb-collection");b.off("buttons-action.b-internal")}})},10);if(d.autoClose)b.on("buttons-action.b-internal",function(){e("div.dt-button-background").click()})},background:!0,collectionLayout:"",backgroundClassName:"dt-button-background",autoClose:!1,fade:400},copy:function(a,b){if(l.copyHtml5)return"copyHtml5";if(l.copyFlash&&l.copyFlash.available(a,
b))return"copyFlash"},csv:function(a,b){if(l.csvHtml5&&l.csvHtml5.available(a,b))return"csvHtml5";if(l.csvFlash&&l.csvFlash.available(a,b))return"csvFlash"},excel:function(a,b){if(l.excelHtml5&&l.excelHtml5.available(a,b))return"excelHtml5";if(l.excelFlash&&l.excelFlash.available(a,b))return"excelFlash"},pdf:function(a,b){if(l.pdfHtml5&&l.pdfHtml5.available(a,b))return"pdfHtml5";if(l.pdfFlash&&l.pdfFlash.available(a,b))return"pdfFlash"},pageLength:function(a){var a=a.settings()[0].aLengthMenu,b=e.isArray(a[0])?
a[0]:a,c=e.isArray(a[0])?a[1]:a,d=function(a){return a.i18n("buttons.pageLength",{"-1":"Show all rows",_:"Show %d rows"},a.page.len())};return{extend:"collection",text:d,className:"buttons-page-length",autoClose:!0,buttons:e.map(b,function(a,b){return{text:c[b],action:function(b,c){c.page.len(a).draw()},init:function(b,c,d){var e=this,c=function(){e.active(b.page.len()===a)};b.on("length.dt"+d.namespace,c);c()},destroy:function(a,b,c){a.off("length.dt"+c.namespace)}}}),init:function(a,b,c){var e=
this;a.on("length.dt"+c.namespace,function(){e.text(d(a))})},destroy:function(a,b,c){a.off("length.dt"+c.namespace)}}}});j.Api.register("buttons()",function(a,b){b===n&&(b=a,a=n);return this.iterator(!0,"table",function(c){if(c._buttons)return m.buttonSelector(m.instanceSelector(a,c._buttons),b)},!0)});j.Api.register("button()",function(a,b){var c=this.buttons(a,b);1<c.length&&c.splice(1,c.length);return c});j.Api.registerPlural("buttons().active()","button().active()",function(a){return a===n?this.map(function(a){return a.inst.active(a.idx)}):
this.each(function(b){b.inst.active(b.idx,a)})});j.Api.registerPlural("buttons().action()","button().action()",function(a){return a===n?this.map(function(a){return a.inst.action(a.idx)}):this.each(function(b){b.inst.action(b.idx,a)})});j.Api.register(["buttons().enable()","button().enable()"],function(a){return this.each(function(b){b.inst.enable(b.idx,a)})});j.Api.register(["buttons().disable()","button().disable()"],function(){return this.each(function(a){a.inst.disable(a.idx)})});j.Api.registerPlural("buttons().nodes()",
"button().node()",function(){var a=e();e(this.each(function(b){a=a.add(b.inst.node(b.idx))}));return a});j.Api.registerPlural("buttons().text()","button().text()",function(a){return a===n?this.map(function(a){return a.inst.text(a.idx)}):this.each(function(b){b.inst.text(b.idx,a)})});j.Api.registerPlural("buttons().trigger()","button().trigger()",function(){return this.each(function(a){a.inst.node(a.idx).trigger("click")})});j.Api.registerPlural("buttons().containers()","buttons().container()",function(){var a=
e();e(this.each(function(b){a=a.add(b.inst.container())}));return a});j.Api.register("button().add()",function(a,b){1===this.length&&this[0].inst.add(a,b);return this.button(a)});j.Api.register("buttons().destroy()",function(){this.pluck("inst").unique().each(function(a){a.destroy()});return this});j.Api.registerPlural("buttons().remove()","buttons().remove()",function(){this.each(function(a){a.inst.removePrep(a.idx)});this.pluck("inst").unique().each(function(a){a.removeCommit()});return this});
var q;j.Api.register("buttons.info()",function(a,b,c){var d=this;if(!1===a)return e("#datatables_buttons_info").fadeOut(function(){e(this).remove()}),clearTimeout(q),q=null,this;q&&clearTimeout(q);e("#datatables_buttons_info").length&&e("#datatables_buttons_info").remove();e('<div id="datatables_buttons_info" class="dt-button-info"/>').html(a?"<h2>"+a+"</h2>":"").append(e("<div/>")["string"===typeof b?"html":"append"](b)).css("display","none").appendTo("body").fadeIn();c!==n&&0!==c&&(q=setTimeout(function(){d.buttons.info(!1)},
c));return this});j.Api.register("buttons.exportData()",function(a){if(this.context.length){for(var b=new j.Api(this.context[0]),c=e.extend(!0,{},{rows:null,columns:"",modifier:{search:"applied",order:"applied"},orthogonal:"display",stripHtml:!0,stripNewlines:!0,decodeEntities:!0,trim:!0,format:{header:function(a){return d(a)},footer:function(a){return d(a)},body:function(a){return d(a)}}},a),d=function(a){if("string"!==typeof a)return a;c.stripHtml&&(a=a.replace(/<.*?>/g,""));c.trim&&(a=a.replace(/^\s+|\s+$/g,
""));c.stripNewlines&&(a=a.replace(/\n/g," "));c.decodeEntities&&(s.innerHTML=a,a=s.value);return a},a=b.columns(c.columns).indexes().map(function(a){return c.format.header(b.column(a).header().innerHTML,a)}).toArray(),f=b.table().footer()?b.columns(c.columns).indexes().map(function(a){var d=b.column(a).footer();return c.format.footer(d?d.innerHTML:"",a)}).toArray():null,h=b.rows(c.rows,c.modifier).indexes().toArray(),h=b.cells(h,c.columns).render(c.orthogonal).toArray(),i=a.length,k=0<i?h.length/
i:0,g=Array(k),l=0,m=0;m<k;m++){for(var n=Array(i),o=0;o<i;o++)n[o]=c.format.body(h[l],o,m),l++;g[m]=n}return{header:a,footer:f,body:g}}});var s=e("<textarea/>")[0];e.fn.dataTable.Buttons=m;e.fn.DataTable.Buttons=m;e(o).on("init.dt plugin-init.dt",function(a,b){if("dt"===a.namespace){var c=b.oInit.buttons||j.defaults.buttons;c&&!b._buttons&&(new m(b,c)).container()}});j.ext.feature.push({fnInit:function(a){var a=new j.Api(a),b=a.init().buttons||j.defaults.buttons;return(new m(a,b)).container()},cFeature:"B"});
return m});


(function(g){"function"===typeof define&&define.amd?define(["jquery","datatables.net","datatables.net-buttons"],function(d){return g(d,window,document)}):"object"===typeof exports?module.exports=function(d,e){d||(d=window);if(!e||!e.fn.dataTable)e=require("datatables.net")(d,e).$;e.fn.dataTable.Buttons||require("datatables.net-buttons")(d,e);return g(e,d,d.document)}:g(jQuery,window,document)})(function(g,d,e,h){d=g.fn.dataTable;g.extend(d.ext.buttons,{colvis:function(b,a){return{extend:"collection",
text:function(c){return c.i18n("buttons.colvis","Column visibility")},className:"buttons-colvis",buttons:[{extend:"columnsToggle",columns:a.columns}]}},columnsToggle:function(b,a){return b.columns(a.columns).indexes().map(function(c){return{extend:"columnToggle",columns:c}}).toArray()},columnToggle:function(b,a){return{extend:"columnVisibility",columns:a.columns}},columnsVisibility:function(b,a){return b.columns(a.columns).indexes().map(function(c){return{extend:"columnVisibility",columns:c,visibility:a.visibility}}).toArray()},
columnVisibility:{columns:h,text:function(b,a,c){return c._columnText(b,c.columns)},className:"buttons-columnVisibility",action:function(b,a,c,f){b=a.columns(f.columns);a=b.visible();b.visible(f.visibility!==h?f.visibility:!(a.length&&a[0]))},init:function(b,a,c){var f=this,a=b.column(c.columns);b.on("column-visibility.dt"+c.namespace,function(b,a,d,e){!a.bDestroying&&d===c.columns&&f.active(e)}).on("column-reorder.dt"+c.namespace,function(a,d,e){1===b.columns(c.columns).count()&&("number"===typeof c.columns&&
(c.columns=e.mapping[c.columns]),a=b.column(c.columns),f.text(c._columnText(b,c.columns)),f.active(a.visible()))});this.active(a.visible())},destroy:function(b,a,c){b.off("column-visibility.dt"+c.namespace).off("column-reorder.dt"+c.namespace)},_columnText:function(b,a){var c=b.column(a).index();return b.settings()[0].aoColumns[c].sTitle.replace(/\n/g," ").replace(/<.*?>/g,"").replace(/^\s+|\s+$/g,"")}},colvisRestore:{className:"buttons-colvisRestore",text:function(b){return b.i18n("buttons.colvisRestore",
"Restore visibility")},init:function(b,a,c){c._visOriginal=b.columns().indexes().map(function(a){return b.column(a).visible()}).toArray()},action:function(b,a,c,d){a.columns().every(function(b){b=a.colReorder&&a.colReorder.transpose?a.colReorder.transpose(b,"toOriginal"):b;this.visible(d._visOriginal[b])})}},colvisGroup:{className:"buttons-colvisGroup",action:function(b,a,c,d){a.columns(d.show).visible(!0);a.columns(d.hide).visible(!1)},show:[],hide:[]}});return d.Buttons});


(function(g){"function"===typeof define&&define.amd?define(["jquery","datatables.net","datatables.net-buttons"],function(h){return g(h,window,document)}):"object"===typeof exports?module.exports=function(h,f){h||(h=window);if(!f||!f.fn.dataTable)f=require("datatables.net")(h,f).$;f.fn.dataTable.Buttons||require("datatables.net-buttons")(h,f);return g(f,h,h.document)}:g(jQuery,window,document)})(function(g,h,f,m){var i=g.fn.dataTable,e={version:"1.0.4-TableTools2",clients:{},moviePath:"",nextId:1,
$:function(a){"string"==typeof a&&(a=f.getElementById(a));a.addClass||(a.hide=function(){this.style.display="none"},a.show=function(){this.style.display=""},a.addClass=function(a){this.removeClass(a);this.className+=" "+a},a.removeClass=function(a){this.className=this.className.replace(RegExp("\\s*"+a+"\\s*")," ").replace(/^\s+/,"").replace(/\s+$/,"")},a.hasClass=function(a){return!!this.className.match(RegExp("\\s*"+a+"\\s*"))});return a},setMoviePath:function(a){this.moviePath=a},dispatch:function(a,
b,d){(a=this.clients[a])&&a.receiveEvent(b,d)},register:function(a,b){this.clients[a]=b},getDOMObjectPosition:function(a){var b={left:0,top:0,width:a.width?a.width:a.offsetWidth,height:a.height?a.height:a.offsetHeight};""!==a.style.width&&(b.width=a.style.width.replace("px",""));""!==a.style.height&&(b.height=a.style.height.replace("px",""));for(;a;)b.left+=a.offsetLeft,b.top+=a.offsetTop,a=a.offsetParent;return b},Client:function(a){this.handlers={};this.id=e.nextId++;this.movieId="ZeroClipboard_TableToolsMovie_"+
this.id;e.register(this.id,this);a&&this.glue(a)}};e.Client.prototype={id:0,ready:!1,movie:null,clipText:"",fileName:"",action:"copy",handCursorEnabled:!0,cssEffects:!0,handlers:null,sized:!1,sheetName:"",glue:function(a,b){this.domElement=e.$(a);var d=99;this.domElement.style.zIndex&&(d=parseInt(this.domElement.style.zIndex,10)+1);var c=e.getDOMObjectPosition(this.domElement);this.div=f.createElement("div");var j=this.div.style;j.position="absolute";j.left="0px";j.top="0px";j.width=c.width+"px";
j.height=c.height+"px";j.zIndex=d;"undefined"!=typeof b&&""!==b&&(this.div.title=b);0!==c.width&&0!==c.height&&(this.sized=!0);this.domElement&&(this.domElement.appendChild(this.div),this.div.innerHTML=this.getHTML(c.width,c.height).replace(/&/g,"&amp;"))},positionElement:function(){var a=e.getDOMObjectPosition(this.domElement),b=this.div.style;b.position="absolute";b.width=a.width+"px";b.height=a.height+"px";0!==a.width&&0!==a.height&&(this.sized=!0,b=this.div.childNodes[0],b.width=a.width,b.height=
a.height)},getHTML:function(a,b){var d="",c="id="+this.id+"&width="+a+"&height="+b;if(navigator.userAgent.match(/MSIE/))var j=location.href.match(/^https/i)?"https://":"http://",d=d+('<object classid="clsid:D27CDB6E-AE6D-11cf-96B8-444553540000" codebase="'+j+'download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=10,0,0,0" width="'+a+'" height="'+b+'" id="'+this.movieId+'" align="middle"><param name="allowScriptAccess" value="always" /><param name="allowFullScreen" value="false" /><param name="movie" value="'+
e.moviePath+'" /><param name="loop" value="false" /><param name="menu" value="false" /><param name="quality" value="best" /><param name="bgcolor" value="#ffffff" /><param name="flashvars" value="'+c+'"/><param name="wmode" value="transparent"/></object>');else d+='<embed id="'+this.movieId+'" src="'+e.moviePath+'" loop="false" menu="false" quality="best" bgcolor="#ffffff" width="'+a+'" height="'+b+'" name="'+this.movieId+'" align="middle" allowScriptAccess="always" allowFullScreen="false" type="application/x-shockwave-flash" pluginspage="http://www.macromedia.com/go/getflashplayer" flashvars="'+
c+'" wmode="transparent" />';return d},hide:function(){this.div&&(this.div.style.left="-2000px")},show:function(){this.reposition()},destroy:function(){var a=this;this.domElement&&this.div&&(g(this.div).remove(),this.div=this.domElement=null,g.each(e.clients,function(b,d){d===a&&delete e.clients[b]}))},reposition:function(a){a&&((this.domElement=e.$(a))||this.hide());if(this.domElement&&this.div){var a=e.getDOMObjectPosition(this.domElement),b=this.div.style;b.left=""+a.left+"px";b.top=""+a.top+"px"}},
clearText:function(){this.clipText="";this.ready&&this.movie.clearText()},appendText:function(a){this.clipText+=a;this.ready&&this.movie.appendText(a)},setText:function(a){this.clipText=a;this.ready&&this.movie.setText(a)},setFileName:function(a){this.fileName=a;this.ready&&this.movie.setFileName(a)},setSheetName:function(a){this.sheetName=a;this.ready&&this.movie.setSheetName(a)},setAction:function(a){this.action=a;this.ready&&this.movie.setAction(a)},addEventListener:function(a,b){a=a.toString().toLowerCase().replace(/^on/,
"");this.handlers[a]||(this.handlers[a]=[]);this.handlers[a].push(b)},setHandCursor:function(a){this.handCursorEnabled=a;this.ready&&this.movie.setHandCursor(a)},setCSSEffects:function(a){this.cssEffects=!!a},receiveEvent:function(a,b){var d,a=a.toString().toLowerCase().replace(/^on/,"");switch(a){case "load":this.movie=f.getElementById(this.movieId);if(!this.movie){d=this;setTimeout(function(){d.receiveEvent("load",null)},1);return}if(!this.ready&&navigator.userAgent.match(/Firefox/)&&navigator.userAgent.match(/Windows/)){d=
this;setTimeout(function(){d.receiveEvent("load",null)},100);this.ready=!0;return}this.ready=!0;this.movie.clearText();this.movie.appendText(this.clipText);this.movie.setFileName(this.fileName);this.movie.setAction(this.action);this.movie.setHandCursor(this.handCursorEnabled);break;case "mouseover":this.domElement&&this.cssEffects&&this.recoverActive&&this.domElement.addClass("active");break;case "mouseout":this.domElement&&this.cssEffects&&(this.recoverActive=!1,this.domElement.hasClass("active")&&
(this.domElement.removeClass("active"),this.recoverActive=!0));break;case "mousedown":this.domElement&&this.cssEffects&&this.domElement.addClass("active");break;case "mouseup":this.domElement&&this.cssEffects&&(this.domElement.removeClass("active"),this.recoverActive=!1)}if(this.handlers[a])for(var c=0,j=this.handlers[a].length;c<j;c++){var e=this.handlers[a][c];if("function"==typeof e)e(this,b);else if("object"==typeof e&&2==e.length)e[0][e[1]](this,b);else if("string"==typeof e)h[e](this,b)}}};
e.hasFlash=function(){try{if(new ActiveXObject("ShockwaveFlash.ShockwaveFlash"))return!0}catch(a){if(navigator.mimeTypes&&navigator.mimeTypes["application/x-shockwave-flash"]!==m&&navigator.mimeTypes["application/x-shockwave-flash"].enabledPlugin)return!0}return!1};h.ZeroClipboard_TableTools=e;var o=function(a,b){b.attr("id");b.parents("html").length?a.glue(b[0],""):setTimeout(function(){o(a,b)},500)},n=function(a,b){var d="*"===a.filename&&"*"!==a.title&&a.title!==m?a.title:a.filename;"function"===
typeof d&&(d=d());-1!==d.indexOf("*")&&(d=d.replace("*",g("title").text()));d=d.replace(/[^a-zA-Z0-9_\u00A1-\uFFFF\.,\-_ !\(\)]/g,"");return b===m||!0===b?d+a.extension:d},q=function(a){var b="Sheet1";a.sheetName&&(b=a.sheetName.replace(/[\[\]\*\/\\\?\:]/g,""));return b},r=function(a){a=a.title;"function"===typeof a&&(a=a());return-1!==a.indexOf("*")?a.replace("*",g("title").text()):a},k=function(a,b){var d=b.match(/[\s\S]{1,8192}/g)||[];a.clearText();for(var c=0,e=d.length;c<e;c++)a.appendText(d[c])},
p=function(a,b){for(var d=b.newline?b.newline:navigator.userAgent.match(/Windows/)?"\r\n":"\n",c=a.buttons.exportData(b.exportOptions),e=b.fieldBoundary,g=b.fieldSeparator,h=RegExp(e,"g"),s=b.escapeChar!==m?b.escapeChar:"\\",f=function(a){for(var b="",c=0,d=a.length;c<d;c++)0<c&&(b+=g),b+=e?e+(""+a[c]).replace(h,s+e)+e:a[c];return b},t=b.header?f(c.header)+d:"",i=b.footer&&c.footer?d+f(c.footer):"",k=[],l=0,n=c.body.length;l<n;l++)k.push(f(c.body[l]));return{str:t+k.join(d)+i,rows:k.length}},l={available:function(){return e.hasFlash()},
init:function(a,b,d){e.moviePath=i.Buttons.swfPath;var c=new e.Client;c.setHandCursor(!0);c.addEventListener("mouseDown",function(){d._fromFlash=!0;a.button(b[0]).trigger();d._fromFlash=!1});o(c,b);d._flash=c},destroy:function(a,b,d){d._flash.destroy()},fieldSeparator:",",fieldBoundary:'"',exportOptions:{},title:"*",filename:"*",extension:".csv",header:!0,footer:!1};i.Buttons.swfPath="//cdn.datatables.net/buttons/1.0.0/swf/flashExport.swf";i.Api.register("buttons.resize()",function(){g.each(e.clients,
function(a,b){b.domElement!==m&&b.domElement.parentNode&&b.positionElement()})});i.ext.buttons.copyFlash=g.extend({},l,{className:"buttons-copy buttons-flash",text:function(a){return a.i18n("buttons.copy","Copy")},action:function(a,b,d,c){c._fromFlash&&(a=c._flash,d=p(b,c),c=c.customize?c.customize(d.str,c):d.str,a.setAction("copy"),k(a,c),b.buttons.info(b.i18n("buttons.copyTitle","Copy to clipboard"),b.i18n("buttons.copyInfo",{_:"Copied %d rows to clipboard",1:"Copied 1 row to clipboard"},d.rows),
3E3))},fieldSeparator:"\t",fieldBoundary:""});i.ext.buttons.csvFlash=g.extend({},l,{className:"buttons-csv buttons-flash",text:function(a){return a.i18n("buttons.csv","CSV")},action:function(a,b,d,c){a=c._flash;b=p(b,c);b=c.customize?c.customize(b.str,c):b.str;a.setAction("csv");a.setFileName(n(c));k(a,b)},escapeChar:'"'});i.ext.buttons.excelFlash=g.extend({},l,{className:"buttons-excel buttons-flash",text:function(a){return a.i18n("buttons.excel","Excel")},action:function(a,b,d,c){var a="",d=c._flash,
b=b.buttons.exportData(c.exportOptions),e=function(a){for(var b=[],c=0,d=a.length;c<d;c++){if(null===a[c]||a[c]===m)a[c]="";b.push("number"===typeof a[c]||a[c].match&&g.trim(a[c]).match(/^-?\d+(\.\d+)?$/)&&"0"!==a[c].charAt(0)?'<c t="n"><v>'+a[c]+"</v></c>":'<c t="inlineStr"><is><t>'+(!a[c].replace?a[c]:a[c].replace(/&(?!amp;)/g,"&amp;").replace(/</g,"&lt;").replace(/>/g,"&gt;").replace(/[\x00-\x1F\x7F-\x9F]/g,""))+"</t></is></c>")}return"<row>"+b.join("")+"</row>"};c.header&&(a+=e(b.header));for(var f=
0,h=b.body.length;f<h;f++)a+=e(b.body[f]);c.footer&&(a+=e(b.footer));d.setAction("excel");d.setFileName(n(c));d.setSheetName(q(c));k(d,a)},extension:".xlsx"});i.ext.buttons.pdfFlash=g.extend({},l,{className:"buttons-pdf buttons-flash",text:function(a){return a.i18n("buttons.pdf","PDF")},action:function(a,b,d,c){var a=c._flash,d=b.buttons.exportData(c.exportOptions),e=b.table().node().offsetWidth,f=b.columns(c.columns).indexes().map(function(a){return b.column(a).header().offsetWidth/e});a.setAction("pdf");
a.setFileName(r(c));k(a,JSON.stringify({title:n(c,!1),message:c.message,colWidth:f.toArray(),orientation:c.orientation,size:c.pageSize,header:c.header?d.header:null,footer:c.footer?d.footer:null,body:d.body}))},extension:".pdf",orientation:"portrait",pageSize:"A4",message:"",newline:"\n"});return i.Buttons});


(function(g){"function"===typeof define&&define.amd?define(["jquery","datatables.net","datatables.net-buttons"],function(d){return g(d,window,document)}):"object"===typeof exports?module.exports=function(d,f){d||(d=window);if(!f||!f.fn.dataTable)f=require("datatables.net")(d,f).$;f.fn.dataTable.Buttons||require("datatables.net-buttons")(d,f);return g(f,d,d.document)}:g(jQuery,window,document)})(function(g,d,f,k){var l=g.fn.dataTable,j;if("undefined"!==typeof navigator&&/MSIE [1-9]\./.test(navigator.userAgent))j=
void 0;else{var x=d.document,q=x.createElementNS("http://www.w3.org/1999/xhtml","a"),E="download"in q,r=d.webkitRequestFileSystem,y=d.requestFileSystem||r||d.mozRequestFileSystem,F=function(a){(d.setImmediate||d.setTimeout)(function(){throw a;},0)},s=0,t=function(a){var b=function(){"string"===typeof a?(d.URL||d.webkitURL||d).revokeObjectURL(a):a.remove()};d.chrome?b():setTimeout(b,500)},u=function(a,b,e){for(var b=[].concat(b),c=b.length;c--;){var d=a["on"+b[c]];if("function"===typeof d)try{d.call(a,
e||a)}catch(i){F(i)}}},A=function(a){return/^\s*(?:text\/\S*|application\/xml|\S*\/\S*\+xml)\s*;.*charset\s*=\s*utf-8/i.test(a.type)?new Blob(["﻿",a],{type:a.type}):a},B=function(a,b){var a=A(a),e=this,c=a.type,z=!1,i,g,m=function(){u(e,["writestart","progress","write","writeend"])},o=function(){if(z||!i)i=(d.URL||d.webkitURL||d).createObjectURL(a);g?g.location.href=i:d.open(i,"_blank")===k&&"undefined"!==typeof safari&&(d.location.href=i);e.readyState=e.DONE;m();t(i)},n=function(a){return function(){if(e.readyState!==
e.DONE)return a.apply(this,arguments)}},f={create:!0,exclusive:!1},h;e.readyState=e.INIT;b||(b="download");if(E)i=(d.URL||d.webkitURL||d).createObjectURL(a),q.href=i,q.download=b,c=x.createEvent("MouseEvents"),c.initMouseEvent("click",!0,!1,d,0,0,0,0,0,!1,!1,!1,!1,0,null),q.dispatchEvent(c),e.readyState=e.DONE,m(),t(i);else{d.chrome&&(c&&"application/octet-stream"!==c)&&(h=a.slice||a.webkitSlice,a=h.call(a,0,a.size,"application/octet-stream"),z=!0);r&&"download"!==b&&(b+=".download");if("application/octet-stream"===
c||r)g=d;y?(s+=a.size,y(d.TEMPORARY,s,n(function(c){c.root.getDirectory("saved",f,n(function(c){var d=function(){c.getFile(b,f,n(function(b){b.createWriter(n(function(c){c.onwriteend=function(a){g.location.href=b.toURL();e.readyState=e.DONE;u(e,"writeend",a);t(b)};c.onerror=function(){var a=c.error;a.code!==a.ABORT_ERR&&o()};["writestart","progress","write","abort"].forEach(function(a){c["on"+a]=e["on"+a]});c.write(a);e.abort=function(){c.abort();e.readyState=e.DONE};e.readyState=e.WRITING}),o)}),
o)};c.getFile(b,{create:false},n(function(a){a.remove();d()}),n(function(a){a.code===a.NOT_FOUND_ERR?d():o()}))}),o)}),o)):o()}},h=B.prototype;"undefined"!==typeof navigator&&navigator.msSaveOrOpenBlob?j=function(a,b){return navigator.msSaveOrOpenBlob(A(a),b)}:(h.abort=function(){this.readyState=this.DONE;u(this,"abort")},h.readyState=h.INIT=0,h.WRITING=1,h.DONE=2,h.error=h.onwritestart=h.onprogress=h.onwrite=h.onabort=h.onerror=h.onwriteend=null,j=function(a,b){return new B(a,b)})}var v=function(a,
b){var e="*"===a.filename&&"*"!==a.title&&a.title!==k?a.title:a.filename;"function"===typeof e&&(e=e());-1!==e.indexOf("*")&&(e=e.replace("*",g("title").text()));e=e.replace(/[^a-zA-Z0-9_\u00A1-\uFFFF\.,\-_ !\(\)]/g,"");return b===k||!0===b?e+a.extension:e},G=function(a){var b="Sheet1";a.sheetName&&(b=a.sheetName.replace(/[\[\]\*\/\\\?\:]/g,""));return b},H=function(a){a=a.title;"function"===typeof a&&(a=a());return-1!==a.indexOf("*")?a.replace("*",g("title").text()):a},w=function(a){return a.newline?
a.newline:navigator.userAgent.match(/Windows/)?"\r\n":"\n"},C=function(a,b){for(var e=w(b),c=a.buttons.exportData(b.exportOptions),d=b.fieldBoundary,i=b.fieldSeparator,g=RegExp(d,"g"),m=b.escapeChar!==k?b.escapeChar:"\\",f=function(a){for(var b="",c=0,e=a.length;c<e;c++)0<c&&(b+=i),b+=d?d+(""+a[c]).replace(g,m+d)+d:a[c];return b},n=b.header?f(c.header)+e:"",h=b.footer&&c.footer?e+f(c.footer):"",j=[],l=0,p=c.body.length;l<p;l++)j.push(f(c.body[l]));return{str:n+j.join(e)+h,rows:j.length}},D=function(){return-1!==
navigator.userAgent.indexOf("Safari")&&-1===navigator.userAgent.indexOf("Chrome")&&-1===navigator.userAgent.indexOf("Opera")},p={"_rels/.rels":'<?xml version="1.0" encoding="UTF-8" standalone="yes"?><Relationships xmlns="http://schemas.openxmlformats.org/package/2006/relationships">\t<Relationship Id="rId1" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/officeDocument" Target="xl/workbook.xml"/></Relationships>',"xl/_rels/workbook.xml.rels":'<?xml version="1.0" encoding="UTF-8" standalone="yes"?><Relationships xmlns="http://schemas.openxmlformats.org/package/2006/relationships">\t<Relationship Id="rId1" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/worksheet" Target="worksheets/sheet1.xml"/></Relationships>',
"[Content_Types].xml":'<?xml version="1.0" encoding="UTF-8" standalone="yes"?><Types xmlns="http://schemas.openxmlformats.org/package/2006/content-types">\t<Default Extension="xml" ContentType="application/xml"/>\t<Default Extension="rels" ContentType="application/vnd.openxmlformats-package.relationships+xml"/>\t<Default Extension="jpeg" ContentType="image/jpeg"/>\t<Override PartName="/xl/workbook.xml" ContentType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml"/>\t<Override PartName="/xl/worksheets/sheet1.xml" ContentType="application/vnd.openxmlformats-officedocument.spreadsheetml.worksheet+xml"/></Types>',
"xl/workbook.xml":'<?xml version="1.0" encoding="UTF-8" standalone="yes"?><workbook xmlns="http://schemas.openxmlformats.org/spreadsheetml/2006/main" xmlns:r="http://schemas.openxmlformats.org/officeDocument/2006/relationships">\t<fileVersion appName="xl" lastEdited="5" lowestEdited="5" rupBuild="24816"/>\t<workbookPr showInkAnnotation="0" autoCompressPictures="0"/>\t<bookViews>\t\t<workbookView xWindow="0" yWindow="0" windowWidth="25600" windowHeight="19020" tabRatio="500"/>\t</bookViews>\t<sheets>\t\t<sheet name="__SHEET_NAME__" sheetId="1" r:id="rId1"/>\t</sheets></workbook>',
"xl/worksheets/sheet1.xml":'<?xml version="1.0" encoding="UTF-8" standalone="yes"?><worksheet xmlns="http://schemas.openxmlformats.org/spreadsheetml/2006/main" xmlns:r="http://schemas.openxmlformats.org/officeDocument/2006/relationships" xmlns:mc="http://schemas.openxmlformats.org/markup-compatibility/2006" mc:Ignorable="x14ac" xmlns:x14ac="http://schemas.microsoft.com/office/spreadsheetml/2009/9/ac">\t<sheetData>\t\t__DATA__\t</sheetData></worksheet>'};l.ext.buttons.copyHtml5={className:"buttons-copy buttons-html5",
text:function(a){return a.i18n("buttons.copy","Copy")},action:function(a,b,e,c){var a=C(b,c),d=a.str,e=g("<div/>").css({height:1,width:1,overflow:"hidden",position:"fixed",top:0,left:0});c.customize&&(d=c.customize(d,c));c=g("<textarea readonly/>").val(d).appendTo(e);if(f.queryCommandSupported("copy")){e.appendTo(b.table().container());c[0].focus();c[0].select();try{f.execCommand("copy");e.remove();b.buttons.info(b.i18n("buttons.copyTitle","Copy to clipboard"),b.i18n("buttons.copySuccess",{1:"Copied one row to clipboard",
_:"Copied %d rows to clipboard"},a.rows),2E3);return}catch(i){}}a=g("<span>"+b.i18n("buttons.copyKeys","Press <i>ctrl</i> or <i>⌘</i> + <i>C</i> to copy the table data<br>to your system clipboard.<br><br>To cancel, click this message or press escape.")+"</span>").append(e);b.buttons.info(b.i18n("buttons.copyTitle","Copy to clipboard"),a,0);c[0].focus();c[0].select();var h=g(a).closest(".dt-button-info"),m=function(){h.off("click.buttons-copy");g(f).off(".buttons-copy");b.buttons.info(!1)};h.on("click.buttons-copy",
m);g(f).on("keydown.buttons-copy",function(a){27===a.keyCode&&m()}).on("copy.buttons-copy cut.buttons-copy",function(){m()})},exportOptions:{},fieldSeparator:"\t",fieldBoundary:"",header:!0,footer:!1};l.ext.buttons.csvHtml5={className:"buttons-csv buttons-html5",available:function(){return d.FileReader!==k&&d.Blob},text:function(a){return a.i18n("buttons.csv","CSV")},action:function(a,b,d,c){w(c);a=C(b,c).str;b=c.charset;c.customize&&(a=c.customize(a,c));!1!==b?(b||(b=f.characterSet||f.charset),b&&
(b=";charset="+b)):b="";j(new Blob([a],{type:"text/csv"+b}),v(c))},filename:"*",extension:".csv",exportOptions:{},fieldSeparator:",",fieldBoundary:'"',escapeChar:'"',charset:null,header:!0,footer:!1};l.ext.buttons.excelHtml5={className:"buttons-excel buttons-html5",available:function(){return d.FileReader!==k&&d.JSZip!==k&&!D()},text:function(a){return a.i18n("buttons.excel","Excel")},action:function(a,b,e,c){a="";b=b.buttons.exportData(c.exportOptions);e=function(a){for(var b=[],c=0,d=a.length;c<
d;c++){if(null===a[c]||a[c]===k)a[c]="";b.push("number"===typeof a[c]||a[c].match&&g.trim(a[c]).match(/^-?\d+(\.\d+)?$/)&&"0"!==a[c].charAt(0)?'<c t="n"><v>'+a[c]+"</v></c>":'<c t="inlineStr"><is><t>'+(!a[c].replace?a[c]:a[c].replace(/&(?!amp;)/g,"&amp;").replace(/</g,"&lt;").replace(/>/g,"&gt;").replace(/[\x00-\x09\x0B\x0C\x0E-\x1F\x7F-\x9F]/g,""))+"</t></is></c>")}return"<row>"+b.join("")+"</row>"};c.header&&(a+=e(b.header));for(var f=0,i=b.body.length;f<i;f++)a+=e(b.body[f]);c.footer&&(a+=e(b.footer));
var b=new d.JSZip,e=b.folder("_rels"),f=b.folder("xl"),i=b.folder("xl/_rels"),h=b.folder("xl/worksheets");b.file("[Content_Types].xml",p["[Content_Types].xml"]);e.file(".rels",p["_rels/.rels"]);f.file("workbook.xml",p["xl/workbook.xml"].replace("__SHEET_NAME__",G(c)));i.file("workbook.xml.rels",p["xl/_rels/workbook.xml.rels"]);h.file("sheet1.xml",p["xl/worksheets/sheet1.xml"].replace("__DATA__",a));j(b.generate({type:"blob",mimeType:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}),
v(c))},filename:"*",extension:".xlsx",exportOptions:{},header:!0,footer:!1};l.ext.buttons.pdfHtml5={className:"buttons-pdf buttons-html5",available:function(){return d.FileReader!==k&&d.pdfMake},text:function(a){return a.i18n("buttons.pdf","PDF")},action:function(a,b,e,c){w(c);a=b.buttons.exportData(c.exportOptions);b=[];c.header&&b.push(g.map(a.header,function(a){return{text:"string"===typeof a?a:a+"",style:"tableHeader"}}));for(var f=0,e=a.body.length;f<e;f++)b.push(g.map(a.body[f],function(a){return{text:"string"===
typeof a?a:a+"",style:f%2?"tableBodyEven":"tableBodyOdd"}}));c.footer&&b.push(g.map(a.footer,function(a){return{text:"string"===typeof a?a:a+"",style:"tableFooter"}}));a={pageSize:c.pageSize,pageOrientation:c.orientation,content:[{table:{headerRows:1,body:b},layout:"noBorders"}],styles:{tableHeader:{bold:!0,fontSize:11,color:"white",fillColor:"#2d4154",alignment:"center"},tableBodyEven:{},tableBodyOdd:{fillColor:"#f3f3f3"},tableFooter:{bold:!0,fontSize:11,color:"white",fillColor:"#2d4154"},title:{alignment:"center",
fontSize:15},message:{}},defaultStyle:{fontSize:10}};c.message&&a.content.unshift({text:c.message,style:"message",margin:[0,0,0,12]});c.title&&a.content.unshift({text:H(c,!1),style:"title",margin:[0,0,0,12]});c.customize&&c.customize(a,c);a=d.pdfMake.createPdf(a);"open"===c.download&&!D()?a.open():a.getBuffer(function(a){a=new Blob([a],{type:"application/pdf"});j(a,v(c))})},title:"*",filename:"*",extension:".pdf",exportOptions:{},orientation:"portrait",pageSize:"A4",header:!0,footer:!1,message:null,
customize:null,download:"download"};return l.Buttons});


(function(d){"function"===typeof define&&define.amd?define(["jquery","datatables.net","datatables.net-buttons"],function(e){return d(e,window,document)}):"object"===typeof exports?module.exports=function(e,a){e||(e=window);if(!a||!a.fn.dataTable)a=require("datatables.net")(e,a).$;a.fn.dataTable.Buttons||require("datatables.net-buttons")(e,a);return d(a,e,e.document)}:d(jQuery,window,document)})(function(d,e,a){var i=d.fn.dataTable,g=a.createElement("a");i.ext.buttons.print={className:"buttons-print",
text:function(c){return c.i18n("buttons.print","Print")},action:function(c,b,a,f){c=b.buttons.exportData(f.exportOptions);a=function(c,a){for(var b="<tr>",d=0,e=c.length;d<e;d++)b+="<"+a+">"+c[d]+"</"+a+">";return b+"</tr>"};b='<table class="'+b.table().node().className+'">';f.header&&(b+="<thead>"+a(c.header,"th")+"</thead>");for(var b=b+"<tbody>",j=0,i=c.body.length;j<i;j++)b+=a(c.body[j],"td");b+="</tbody>";f.footer&&(b+="<tfoot>"+a(c.footer,"th")+"</tfoot>");var h=e.open("",""),c=f.title;"function"===
typeof c&&(c=c());-1!==c.indexOf("*")&&(c=c.replace("*",d("title").text()));h.document.close();var k="<title>"+c+"</title>";d("style, link").each(function(){var c=k,b=d(this).clone()[0],a;"link"===b.nodeName.toLowerCase()&&(g.href=b.href,a=g.host,-1===a.indexOf("/")&&0!==g.pathname.indexOf("/")&&(a+="/"),b.href=g.protocol+"//"+a+g.pathname+g.search);k=c+b.outerHTML});d(h.document.head).html(k);d(h.document.body).html("<h1>"+c+"</h1><div>"+f.message+"</div>"+b);f.customize&&f.customize(h);setTimeout(function(){f.autoPrint&&
(h.print(),h.close())},250)},title:"*",message:"",exportOptions:{},header:!0,footer:!1,autoPrint:!0,customize:null};return i.Buttons});


